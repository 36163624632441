import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Box,
    Button,
    Divider,
    Stack,
    Paper,
    Tooltip
} from '@mui/material';
import React, { useEffect } from 'react';
import { FormProvider, useForm, useFormContext, useFieldArray } from "react-hook-form";
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next'
import Title from '../shared/Title';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@apollo/client';
import { FIND_SPECIALIST_BY_ID, GET_OFFICES, GET_SPECIALISTS, GET_SPECIALTIES } from '../graphql/queries';
import { CREATE_SPECIALIST, UPDATE_SPECIALIST } from '../graphql/mutations';
import { FormAutocomplete, FormTimePicker, FormTextField, FormSlider } from '../shared/form-components';
import { getOfficeText, getSpecialtyText } from '../shared/label-helpers';
import { graphql } from '@apollo/client/react/hoc';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTenantId } from '../tenant/hooks';
import { CopyAll as CopyAllIcon } from '@mui/icons-material';
import { FormSelectMultipleServicesQuery } from '../service/form-components';

const  FormAutocompleteGetSpecialtyQuery = graphql(GET_SPECIALTIES, {
    props: ({ data: { getSpecialties , loading}}) => ({
        options: getSpecialties || [],
        loading: loading
    }),
    skip : ({loading}) => loading,
    options: ({tenantId})=> ({
        variables: {
            enabled: true,
            tenants: [tenantId]
        }
    })
})(({...props})=><FormAutocomplete 
    getOptionLabel={getSpecialtyText} 
    {...props} 
/>);


const  FormAutocompleteGetOfficeQuery = graphql(GET_OFFICES, {
    props: ({ data: { getOffices , loading}}) => ({
        options: getOffices || [],
        loading: loading
    }),
    skip: ({loading}) => loading,
    options: ({tenantId}) => ({
        variables: {
            enabled: true,
            tenants: [tenantId]
        }
    })
})(({...props})=><FormAutocomplete 
    getOptionLabel={getOfficeText} 
    {...props} 
/>);


const ScheduleDayRow = ({allowDownward, name, onRemove, onInsertAfter, onCopy, minTime, maxTime, specialistIds}) => {
    const { t } = useTranslation();
    const [tenantId] = useTenantId();

    return (
    <Stack direction='column' mb={2} spacing={1}>
        <FormSelectMultipleServicesQuery
            label={t("services")}
            size='small'
            specialistIds={specialistIds}
            name={`${name}.services`} 
            placeholder={t("SearchAndAddService")}
            tenantId={tenantId}
        />
        <Stack direction='row' spacing={1}>
            <FormTimePicker 
                label={t("from")}
                size='small'
                ampm
                maxTime={maxTime ? moment(maxTime, 'hh:mm a') : undefined}
                name={`${name}.from`} />
            <FormTimePicker 
                label={t("to")}
                size='small'
                ampm
                minTime={minTime ? moment(minTime, 'hh:mm a') : undefined}
                name={`${name}.to`} />
            <Tooltip title={t('PatientCountTooltip')} placement="top">
                <FormTextField
                    label={t("Patients")}
                    size='small'
                    type='number'
                    name={`${name}.count`} />
            </Tooltip>
            <Button 
                color='error'
                variant='contained'
                size='small'
                onClick={onRemove}>
                <DeleteIcon />
            </Button>
            <Button 
                disabled={!allowDownward}
                color='primary'
                variant='contained'
                size='small'
                onClick={onCopy}>
                <CopyAllIcon />
            </Button>
            <Button 
                disabled={!allowDownward}
                color='primary'
                variant='contained'
                size='small'
                onClick={onInsertAfter}>
                <ArrowDownwardIcon />
            </Button>
        </Stack>
    </Stack>)
}

const ScheduleDay = ({name, title, specialistIds}) => {
    const { control, watch } = useFormContext();

    const { fields, append, prepend, remove, swap, move, insert, update } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: name, // unique name for your Field Array
    });

    const handleAddedNew = (value) => {
        append({});
    }

    const handleDelete = (index) => () => {
        remove(index);
    }

    const handleInsertAfter = (current, index) => () => {
        const diff =  moment(current.to, 'hh:mm a').diff(moment(current.from, 'hh:mm a'), 'minutes');
        insert(index+1,{
            services: current.services,
            from: moment(current.to, 'hh:mm a').add(0, 'minutes').format('LT'),
            to: moment(current.to, 'hh:mm a').add(diff, 'minutes').format('LT'),
            count: current.count
        });
    }
    const handleInsert = (current, index) => () => {
        const diff =  moment(current.to, 'hh:mm a').diff(moment(current.from, 'hh:mm a'), 'minutes');
        insert(index+1,{
            services: current.services,
            from: current.from,
            to: current.to,
            count: current.count
        });
    }
    const { t } = useTranslation();

    return (
        <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
            <Typography>{t(title)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack>
                    {fields.map((f, i)=>{
                        const services = watch(`${name}.${i}.services`);
                        const from = watch(`${name}.${i}.from`);
                        const to = watch(`${name}.${i}.to`);
                        const count = watch(`${name}.${i}.count`);
 
                        return <ScheduleDayRow 
                            specialistIds={specialistIds}
                            allowDownward={!!from && !!to && !!count}
                            key={f.id}
                            minTime={from}
                            maxTime={to}
                            onInsertAfter={
                                handleInsertAfter({
                                    services: services,
                                    from: from,
                                    to: to,
                                    count: count
                                }, i)
                            }
                            onCopy={
                                handleInsert({
                                    services: services,
                                    from: from,
                                    to: to,
                                    count: count
                                }, i)
                            }
                            onRemove={handleDelete(i)}
                            name={`${name}.${i}`}
                        />;
                    })}
                    <Button 
                        variant='contained' 
                        color='primary'
                        onClick={handleAddedNew}>
                        <AddIcon />
                    </Button>
                </Stack>

            </AccordionDetails>
        </Accordion>
    );
}

const weekDays = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday'
];

export const ScheduleSection = ({name, specialistIds}) => {
    const {t} = useTranslation();
    return (
        <>
            <Divider>
                {t('Schedule')}
            </Divider>
            <Stack spacing={2}>
                {weekDays.map((wd)=><ScheduleDay key={wd} specialistIds={specialistIds} name={`${name}.${wd}`} title={wd} />)}
            </Stack>
        </>
    );
}


export default ({specialistId, onCancel, onAdded}) => {
    const isNew = !specialistId;

    const [tenantId] = useTenantId();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [ createSpecialist] = useMutation(isNew ? CREATE_SPECIALIST : UPDATE_SPECIALIST,{
        refetchQueries: [
            GET_SPECIALISTS,
            FIND_SPECIALIST_BY_ID
        ]
    })
 
    const {data, loading} = useQuery(FIND_SPECIALIST_BY_ID, {
        variables: {
            specialistId: specialistId
        },
        skip: isNew
    });

    const ScheduleTimeSchema = yup.object({
        count: yup.number().required(t('Required')),
        from: yup.string().required(t('Required')),
        to: yup.string().required(t('Required'))
    }).transform(value=>{
        return {
            ...value,
            name: value.from,
        }
    })

    const ScheduleDaySchema = yup.array(ScheduleTimeSchema).required();


    const schema = yup.object({
        _id: yup.string(),
        name: yup.string().required(t('RequiredField', {Field: t('Name')})),
        specialtyId: yup.string().required(t('RequiredField', {Field: t('Specialty')})),
        officeId: yup.string(),
        code: yup.string().required(t('RequiredField', {Field: t('Code')})),
        schedule: yup.object({
            monday: ScheduleDaySchema,
            tuesday: ScheduleDaySchema,
            wednesday: ScheduleDaySchema,
            thursday: ScheduleDaySchema,
            friday: ScheduleDaySchema,
            saturday: ScheduleDaySchema,
            sunday: ScheduleDaySchema
        }).required(),
        commissionPercent: yup.number().nullable(true)
    });

    const methods = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        defaultValues: {
            _id: specialistId
        }
    });
    const { setValue, handleSubmit, formState: { isSubmitting } } = methods;

    const onSubmit = async data => {
        const response = await createSpecialist({
            variables: {
                input: data,
                ...(isNew ? {tenants:[tenantId]}: {})
            }
        });
        onAdded && onAdded({
            ...data,
            ...response.data.createSpecialist
        });

        enqueueSnackbar(t("OperationDoneSuccessfully"), {variant: "success"});
    }
    
    useEffect(()=>{
        if (!data?.findSpecialist) {
            return;
        }

        setValue("name", data.findSpecialist.name);
        setValue("code", data.findSpecialist.code);
        setValue("specialtyId", data.findSpecialist.specialty?._id);
        setValue("officeId", data.findSpecialist.office?._id);
        setValue("schedule", data.findSpecialist.schedule);
        setValue("commissionPercent", data.findSpecialist.commissionPercent??0);

        for (let weekDay of weekDays){
            setValue(`schedule.${weekDay}`, data.findSpecialist.schedule[weekDay]);
        }
        
    }, [data]);

    return (
        <Paper>
            <FormProvider {...methods}>
                    <Box p={2}>
                        <Title sx={{marginBottom: 0}}>{t(isNew?"CreateSpecialist":"EditSpecialist")}</Title>
                    </Box>
                    <Divider />
                    <Box p={2}>
                        <Stack spacing={2}>
                            <FormTextField 
                                label={t('Name')}
                                name="name"
                                variant="outlined"
                                fullWidth
                            /> 
                            <FormTextField 
                                label={t('Code')}
                                name="code"
                                variant="outlined"
                                fullWidth
                            />
                            <FormAutocompleteGetSpecialtyQuery 
                                label={t('Specialty')}
                                name="specialtyId"
                                loading={loading}
                                tenantId={tenantId}
                                fullWidth
                            />

                            <FormAutocompleteGetOfficeQuery
                                label={t('Office')}
                                name="officeId"
                                loading={loading}
                                tenantId={tenantId}
                                fullWidth
                            />
                        </Stack>
                        <ScheduleSection specialistIds={isNew ? undefined : [specialistId]} name='schedule'/>
                    </Box>
                    <Divider>{t("Commissions")}</Divider>
                    <Box p={2}>
                        <Stack>
                            <FormSlider
                                label={t('Percent')}
                                name="commissionPercent"
                            />
                        </Stack>
                    </Box>
                    <Divider />
                    <Box p={2}>
                        <Stack direction="row" justifyContent="space-between">
                            <Button variant='contained' color="grey" onClick={onCancel} disabled={isSubmitting}>{t('Cancel')}</Button>
                            <LoadingButton 
                                variant='contained' 
                                color='primary' 
                                loading={isSubmitting}
                                onClick={handleSubmit(onSubmit)}>{t('Submit')}</LoadingButton>
                        </Stack>
                    </Box>
            </FormProvider>
        </Paper>
    )
}