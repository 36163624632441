import { 
    FormAutocomplete,
    FormSelectMultiple
} from '../shared/form-components';
import { graphql } from '@apollo/client/react/hoc';
import {
    GET_SERVICES,
} from '../graphql/queries'
import { getServiceText, getSpecialistText } from '../shared/label-helpers';

export const  FormAutocompleteGetServicesQuery = graphql(GET_SERVICES, {
    props: ({ data: { getServices , loading} }) => ({
        options: getServices || [],
        loading: loading,
        groupBy: (service) => getSpecialistText(service.specialist),
    }),
    options: ({specialistIds, tenantId}) => ({
        // Options are computed from `props` here.
        variables: {
            specialistIds: specialistIds||'',
            enabled: true,
            tenants: [tenantId]
        }
    }),
})(({specialistIds, ...props})=><FormAutocomplete 
    getOptionLabel={getServiceText} {...props} 
/>);


export const FormSelectMultipleServicesQuery = graphql(GET_SERVICES, {
    props: ({ data: { getServices , loading } }) => ({
        options: getServices || [],
        loading: loading,
        getOptionLabel: getServiceText,
        getOptionValue: (t)=> t._id
    }),
    skip: ({specialistIds, loading}) => !specialistIds || loading,
    options: ({tenantId, specialistIds})=> ({
        variables: {
            specialistIds: specialistIds,
            tenants: [tenantId]
        }
    })
})(FormSelectMultiple);
