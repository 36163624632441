import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, InputLabel, Paper, Stack, Tab, Typography } from "@mui/material"
import { FormProvider, useForm } from "react-hook-form"
import Title from "../shared/Title"
import { useTranslation } from "react-i18next"
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { useTenantId } from "./hooks";
import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import Confirmation from "../shared/Confirmation";
import { enqueueSnackbar } from "notistack";
import { FIND_TENANT_BY_ID, GET_CURRENT_USER, GET_TENANT_WHATSAPP_INFO_BY_ID } from "../graphql/queries";
import { FormAutocomplete, FormSelectMultiple, FormSwitch, FormTextField, FormTimePicker } from "../shared/form-components";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import QRCode from "react-qr-code";
import countries from "../shared/constants/countries";
import { CREATE_TENANT, LOGOUT_TENANT_WHATSAPP_SESSION, START_TENANT_WHATSAPP_SESSION, UPDATE_TENANT } from "../graphql/mutations";
import weekDays from "../shared/constants/weekDays";
import currencies from "../shared/constants/currencies";
import { ExpandMore } from "@mui/icons-material";
import TenantWhatsAppTestForm from "./TenantWhatsAppTestForm";

const patientSettingsSchema = yup.object({
    birthdayRequired: yup.bool(),
    phoneNumberRequired: yup.bool(),
    requiresGuardian: yup.bool(),
    showPatientNotes: yup.bool(),
});

const whatsAppSettingsSchema = yup.object({
    enabled: yup.bool(),
    integrationType: yup.string(),
    phoneNumber: yup.string(),
    initialized: yup.bool(),
    linkStatus: yup.string(),
    qrCode: yup.string()
});

const generalSettingsSchema = yup.object({
    country: yup.string(),
    currencyFormat: yup.string(),
    weekOffDays: yup.array(yup.number()),
    dateFormat: yup.string(),
    notificationSchedule: yup.string()
});

const settingsSchema = yup.object({
    patientSettings: patientSettingsSchema,
    whatsAppSettings: whatsAppSettingsSchema,
    generalSettings: generalSettingsSchema
});


const tenantSchema = yup.object({
    _id: yup.string(),
    name: yup.string().required(),
    address: yup.string(),
    phoneNumber: yup.string(),
    registryCode: yup.string(),
    email: yup.string(),
    settings: settingsSchema
});

const LINKING_MILLISECONDS = 5000;

export default ({
    onBack,
    onSaved,
    isCreating
}) => {
    const { t } = useTranslation();
    const [tenantId] = useTenantId();
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [tab, setTab] = useState("0");
    const [linking, setLinking] = useState(false);
    const [disableLinkBtn, setDisableLinkBtn] = useState(false);

    const {data: tenantData, loading: tenantLoading} = useQuery(FIND_TENANT_BY_ID, {
        variables: {
            tenantId: tenantId

        },
        skip: isCreating
    })

    const {data: tenantWhatsAppData, loading: tenantWhatsAppLoading, refetch} = useQuery(GET_TENANT_WHATSAPP_INFO_BY_ID, {
        skip: true
    });

    const [updateTenant, {data:updateTenantData, loading:updateTenantLoading}] = useMutation(isCreating ? CREATE_TENANT : UPDATE_TENANT, {
        refetchQueries: [
            FIND_TENANT_BY_ID,
            GET_CURRENT_USER
        ],
        onCompleted: ()=>{
            enqueueSnackbar(t("OperationDoneSuccessfully"), {variant: "success"});
        }
    });

    const [startTenantWhatsAppSession, {data:startTenantWhatsAppSessionData, loading:startTenantWhatsAppSessionLoading}] = useMutation(START_TENANT_WHATSAPP_SESSION, {
        onCompleted: ()=>{
            enqueueSnackbar(t("OperationDoneSuccessfully"), {variant: "success"});
        }
    });


    const [logoutTenantWhatsAppSession, {data:logoutTenantWhatsAppSessionData, loading:logoutTenantWhatsAppSessionLoading}] = useMutation(LOGOUT_TENANT_WHATSAPP_SESSION, {
        onCompleted: ()=>{
            enqueueSnackbar(t("OperationDoneSuccessfully"), {variant: "success"});
        }
    });

    const methods = useForm({
        resolver: yupResolver(tenantSchema),
        mode: 'all',
        values: {}
    });

    const  { watch, control, handleSubmit, setValue } = methods;

    const showConfirmation = (_, e) => {
        setOpenConfirmation(true);
    }

    const onSubmit = async data => {
        const { whatsAppSettings, ...otherSettings } = data.settings
        const { linkStatus, initialized, qrCode, ...otherProps } = data.settings.whatsAppSettings;

        const settings = { ...otherSettings }
        if (!isCreating) {
            settings.whatsAppSettings = {...otherProps}
        }

        await updateTenant({
            variables: {
                input: {
                    ...data,
                    settings: settings
                }
            }
        });
        onSaved && onSaved();
    }

    const handleClose = () => {
        setOpenConfirmation(false);
    }

    useEffect(()=>{
        if (!tenantData?.findTenant) {
            return;
        }

        if (!isCreating){
            setValue('_id', tenantData.findTenant._id);
        }
        setValue('name', tenantData.findTenant.name);
        setValue('address', tenantData.findTenant.address || '');
        setValue('phoneNumber', tenantData.findTenant.phoneNumber || '');
        setValue('registryCode', tenantData.findTenant.registryCode|| '');
        setValue('email', tenantData.findTenant.email || '');
        
        setValue('settings.patientSettings.birthdayRequired', tenantData.findTenant.settings?.patientSettings?.birthdayRequired || false);
        setValue('settings.patientSettings.phoneNumberRequired', tenantData.findTenant.settings?.patientSettings?.phoneNumberRequired || false);
        setValue('settings.patientSettings.requiresGuardian', tenantData.findTenant.settings?.patientSettings?.requiresGuardian || false);
        setValue('settings.patientSettings.showPatientNotes', tenantData.findTenant.settings?.patientSettings?.showPatientNotes || false);
        
        setValue('settings.whatsAppSettings.enabled', tenantData.findTenant.settings?.whatsAppSettings?.enabled || false);
        setValue('settings.whatsAppSettings.integrationType', tenantData.findTenant.settings?.whatsAppSettings?.integrationType || '');
        setValue('settings.whatsAppSettings.phoneNumber', tenantData.findTenant.settings?.whatsAppSettings?.phoneNumber || '');
        setValue('settings.whatsAppSettings.initialized', tenantData.findTenant.settings?.whatsAppSettings?.initialized || false);
        setValue('settings.whatsAppSettings.qrCode', tenantData.findTenant.settings?.whatsAppSettings?.qrCode || '');
        setValue('settings.whatsAppSettings.linkStatus', tenantData.findTenant.settings?.whatsAppSettings?.linkStatus || '');
   
        setValue('settings.generalSettings.country', tenantData.findTenant.settings?.generalSettings?.country || '');
        setValue('settings.generalSettings.currencyFormat', tenantData.findTenant.settings?.generalSettings?.currencyFormat || '');
        setValue('settings.generalSettings.weekOffDays', tenantData.findTenant.settings?.generalSettings?.weekOffDays || []);
        setValue('settings.generalSettings.dateFormat', tenantData.findTenant.settings?.generalSettings?.dateFormat || '');
        setValue('settings.generalSettings.notificationSchedule', tenantData.findTenant.settings?.generalSettings?.notificationSchedule || '');
    }, [tenantData]);

    useEffect(()=>{
        return ()=>{
            if (linking) {
                clearInterval(linking);
            }
        }
    }, [linking]);

    useEffect(()=>{
        if (!tenantWhatsAppData) {
            return;
        }

        setValue('settings.whatsAppSettings.initialized', tenantWhatsAppData.findTenant.settings?.whatsAppSettings?.initialized || false);
        setValue('settings.whatsAppSettings.qrCode', tenantWhatsAppData.findTenant.settings?.whatsAppSettings?.qrCode || '');
        setValue('settings.whatsAppSettings.linkStatus', tenantWhatsAppData.findTenant.settings?.whatsAppSettings?.linkStatus || '');

        if (disableLinkBtn && tenantWhatsAppData.findTenant.settings?.whatsAppSettings?.linkStatus != 'Linking') {
            setDisableLinkBtn(false);
        }
        
    }, [tenantWhatsAppData]);

    
    const handleTabChanged = (_, tab) => {
        setTab(tab);
    }

    const handleStartWhatsAppLink = async () => {
        setDisableLinkBtn(true);
        await startTenantWhatsAppSession({
            variables: {
                tenantId: tenantId
            }
        });
        refetch({
            tenantId: tenantId
        });
    }

    const handleLogoutWhatsAppLink = async () => {
        await logoutTenantWhatsAppSession({
            variables: {
                tenantId: tenantId
            }
        });
        setDisableLinkBtn(false);
        refetch({
            tenantId: tenantId
        });
    }

    const qrCode = watch('settings.whatsAppSettings.qrCode');
    const linkStatus = watch('settings.whatsAppSettings.linkStatus');

    useEffect(()=>{
        if (linking && linkStatus!='Linking') {
            clearInterval(linking);
            setLinking(false);
        } else if (!linking && linkStatus=='Linking') {
            const intervalId = setInterval(() => {
                refetch({
                    tenantId: tenantId
                });
            }, LINKING_MILLISECONDS);
            setLinking(intervalId);
        }
    }, [linkStatus, linking]);

    return <>
        <Confirmation
            open={openConfirmation}
            onNo={handleClose}
            onClose={handleClose}
            onYes={handleSubmit(onSubmit)}
            title={t('Confirmation')}
            description={t('AreYouSureQuestion')}
        />
        <FormProvider>
            <Paper>
                <Box p={2}>
                    <Title sx={{marginBottom: 0}}>{t(isCreating ? 'CreateTenant' : 'EditTenant')}</Title>
                </Box>
                <Divider />
                <TabContext value={tab}>
                    <TabList onChange={handleTabChanged}>
                        <Tab label={t("Info")} value="0"></Tab>
                        <Tab label={t("General")} value="1"></Tab>
                        <Tab sx={isCreating? {display: 'inline'}: {}} label={t("WhatsApp")} value="2"></Tab>
                        <Tab label={t("Patient")} value="3"></Tab>
                    </TabList>
                    <TabPanel value="0">
                        <Stack spacing={2}>
                            <FormTextField 
                                name='name'
                                label={t('Name')}
                                control={control}
                            />
                            <FormTextField 
                                fullWidth
                                rows={3}
                                multiline={true} 
                                name='address'
                                label={t('Address')}
                                control={control}
                            />
                            <FormTextField 
                                name='phoneNumber'
                                label={t('PhoneNumber')}
                                control={control}
                            />
                            <FormTextField 
                                name='registryCode'
                                label={t('RegistryCode')}
                                control={control}
                            />
                            <FormTextField 
                                name='email'
                                label={t('Email')}
                                control={control}
                            />
                        </Stack>
                    </TabPanel>
                    <TabPanel value="1">
                        <Stack spacing={2}>
                            <FormAutocomplete
                                name='settings.generalSettings.country'
                                label={t('Country')}
                                control={control}
                                options={countries}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        <img
                                            loading="lazy"
                                            width="20"
                                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                            alt=""
                                        />
                                        {option.label} ({option.code}) +{option.phone}
                                    </Box>
                                )}
                                getOptionLabel={(opt)=>{
                                    return  (opt?.code) ?  `${opt.label} (${opt.code}) +${opt.phone}` : (opt || '');
                                }}
                                getOptionValue={(opt)=> {
                                    return opt?.code || opt || '';
                                }}
                            />
                            <FormAutocomplete
                                name='settings.generalSettings.currencyFormat'
                                label={t('CurrencyFormat')}
                                control={control}
                                options={currencies}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        {option.name_plural} ({option.symbol_native})
                                    </Box>
                                )}
                                getOptionLabel={(opt)=>{
                                    return  (opt?.symbol_native) ?  `${opt.name_plural} (${opt.symbol_native})` : (opt || '');
                                }}
                                getOptionValue={(opt)=> {
                                    return opt?.symbol_native || opt || '';
                                }}
                            />
                            <FormSelectMultiple
                                name='settings.generalSettings.weekOffDays'
                                label={t('WeekOffDays')}
                                control={control}
                                options={weekDays}
                                getOptionValue={(opt)=> (opt?.index || opt?.index===0) ? opt.index : opt}
                                getOptionLabel={(opt)=>{
                                    return  opt?.name || opt ;
                                }}
                                getKey={(opt)=> (opt?.index || opt?.index===0) ? opt.index : opt}
                            />
                            <FormTextField 
                                name='settings.generalSettings.registryCode'
                                label={t('RegistryCode')}
                                control={control}
                            />
                            <FormTimePicker 
                                name='settings.generalSettings.notificationSchedule'
                                label={t('NotificationSchedule')}
                                minutesStep={60}
                                control={control}
                            />
                        </Stack>
                    </TabPanel>
                    <TabPanel value="2">
                        <Stack spacing={2}>
                            <FormSwitch 
                                name='settings.whatsAppSettings.enabled'
                                label={t('Enabled')}
                                control={control}
                            />
                            <FormTextField 
                                name='settings.whatsAppSettings.integrationType'
                                label={t('integrationType')}
                                control={control}
                            />
                            <FormTextField 
                                name='settings.whatsAppSettings.phoneNumber'
                                label={t('PhoneNumber')}
                                control={control}
                            />
                            <FormSwitch 
                                disabled={true}
                                name='settings.whatsAppSettings.initialized'
                                label={t('Initialized')}
                                control={control}
                            />
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                >
                                    <Typography>{t("Link Whatsapp")}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack>
                                        { linkStatus=='Done' && <Button onClick={handleLogoutWhatsAppLink}>{t('LogoutWhatsAppLink')}</Button>}
                                        { linkStatus!='Done' && <Button disabled={disableLinkBtn || startTenantWhatsAppSessionLoading} onClick={handleStartWhatsAppLink}>{t('StartWhatsAppLink')}</Button>}
                                        {
                                            qrCode && <>
                                                <InputLabel>{t('QrCode')}</InputLabel>
                                                <QRCode
                                                    size={256}
                                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                    value={qrCode}
                                                    viewBox={`0 0 256 256`}
                                                    />
                                            </>
                                        }
                                        {
                                            linkStatus=='Done' && <TenantWhatsAppTestForm />
                                        }
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                        </Stack>
                    </TabPanel>
                    <TabPanel value="3">
                        <Stack spacing={2}>
                            <FormSwitch
                                name='settings.patientSettings.birthdayRequired'
                                label={t('BirthdayRequired')}
                                control={control}
                            />
                            <FormSwitch
                                name='settings.patientSettings.phoneNumberRequired'
                                label={t('PhoneNumberRequired')}
                                control={control}
                            />
                            <FormSwitch 
                                name='settings.patientSettings.requiresGuardian'
                                label={t('RequiresGuardian')}
                                control={control}
                            />
                            <FormSwitch 
                                name='settings.patientSettings.showPatientNotes'
                                label={t('ShowPatientNotes')}
                                control={control}
                            />
                        </Stack>
                    </TabPanel>
                </TabContext>
                <Divider />
                <Box p={2} >
                    <Stack direction="row" justifyContent="space-between">
                        {!isCreating && <Button variant='contained' color="grey" onClick={onBack}>{t('Back')}</Button>}
                        <Button variant='contained' color='primary' onClick={handleSubmit(showConfirmation)} >{t('Save')}</Button>
                    </Stack>
                </Box>
            </Paper>
        </FormProvider>
    </>
}