import { useMutation } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import { Switch, Tooltip } from "@mui/material";
import { Stack } from "@mui/system";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { 
    REQUEST_NEXT_APPOINTMENT,
    COMPLETE_APPOINTMENT
} from '../graphql/mutations';

import { 
    GET_QUEUED_APPOINTMENTS
} from '../graphql/queries';

const CompleteAppointmentButton = ({appointment, onCompleted, disabled}) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [ completeAppointment, { data, loading, error }] = useMutation(
        COMPLETE_APPOINTMENT, {
            refetchQueries: [],
            onCompleted: ()=>{
                enqueueSnackbar(t("OperationDoneSuccessfully"), {variant: "success"});
            }
        });

    const handleComplete = async () => {
        await completeAppointment({
            variables:{
                appointmentId: appointment._id
            },
            refetchQueries: [
                GET_QUEUED_APPOINTMENTS
            ]
        });
        onCompleted && onCompleted()
    }

    return (
        <LoadingButton 
            variant="contained" 
            color="primary" 
            onClick={handleComplete}
            disabled={disabled}
            loading={loading}>
            {t('CompleteAppointment')}
        </LoadingButton>
    );
}

const RequestNextAppointmentButton = ({specialistId, onRequested}) => {
    const { t } = useTranslation();
    const [ ignoreAvailableTime, setIgnoreAvailableTime ] = useState(false);

    const [ requestNextTurn, { data, loading, error }] = useMutation(
        REQUEST_NEXT_APPOINTMENT, {
            refetchQueries: []
        });

    const handleClick = async () => {
        await requestNextTurn({
            variables: {
                specialistId: specialistId
            }
        });
        onRequested && onRequested();
    };

    const handleIgnoreAvailableTime = (_,v) => {
        setIgnoreAvailableTime(v);
    }

    return (
        <Stack direction='row'>
            <LoadingButton 
                variant="contained" 
                color="primary" 
                loading={loading}
                onClick={handleClick}
                fullWidth>
                {t("RequestNextTurn")}
            </LoadingButton>
            <Tooltip
                title={t('IgnoreAvailableTime')}
            >
                <Switch 
                    value={ignoreAvailableTime}
                    onChange={handleIgnoreAvailableTime}
                />
            </Tooltip>
        </Stack>
    );
}

const NoMoreWorkButton = ({onClick, loading}) => {
    const {t} = useTranslation();
    return (<LoadingButton 
        variant="contained" 
        color="primary" 
        loading={loading}
        onClick={onClick}>
        {t('Refresh')}
    </LoadingButton>)
}

export default ({loading, specialistId, appointments, onRefetch, isCurrentWorking}) => {
    const workings = appointments?.filter(a=>a.status=="WORKING") ?? [];
    const queueds = appointments?.filter(a=>a?.status=="QUEUED") ?? [];
    const handleRefresh = () => {
        onRefetch && onRefetch()
    }

    if(workings.length>0){
        return (workings.map(a=>{
            return <CompleteAppointmentButton 
                disabled={!isCurrentWorking}
                key={a._id}
                appointment={a} />
        }));
    } else if (queueds.length>0) {
        return (
            <RequestNextAppointmentButton 
                specialistId={specialistId} 
                onRequested={handleRefresh}
            />);
    } else {
        return (
            <NoMoreWorkButton 
                loading={loading} 
                onClick={handleRefresh}/>
        );
    }
}