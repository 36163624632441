import { Box, Button, TextField, Grid, CircularProgress, Typography } from "@mui/material"
import { GET_SPECIALTIES } from '../graphql/queries';
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { useState } from "react";
import { useTenantId } from "../tenant/hooks";
import { Masonry } from "@mui/lab";

export default ({onSpecialtyClick}) => {
    const [tenantId] = useTenantId();
    const { t } = useTranslation();
    const {data, loading} = useQuery(GET_SPECIALTIES, {
        skip: !tenantId,
        variables: {
            enabled: true,
            tenants: [tenantId]
        }
    });

    const [search, setSearch] = useState('');

    const onSearchTextChanged = ($event) => {
        setSearch($event.target.value);
    }

    const onSpecialtyButtonClicked = (specialty)=> {
        onSpecialtyClick && onSpecialtyClick(specialty) 
    }

    const list = data?.getSpecialties?.filter(s=>s.name?.toLowerCase()?.indexOf(search.toLowerCase())>=0);

    return (
        <>
            <Box pt={2} pb={2}>
                <TextField value={search} onChange={onSearchTextChanged} fullWidth size="small" label={t("Specialties")} placeholder={t('Search')}/>
            </Box>
            {loading ? 
                <CircularProgress />
                :    
                <Masonry spacing={2} columns={{ xs: 2, sm: 3, md: 4 }}>
                    {
                        (list && list.length==0) && 
                        (
                            <Typography>
                                {t('ThereIsNotData')}
                            </Typography>
                       )
                    }
                    {list?.map(s=>{
                        return (
                            <Button key={s._id} variant="contained" onClick={()=>onSpecialtyButtonClicked(s)} fullWidth>
                                <Box p={5}>
                                    {s.name}
                                </Box>
                            </Button>
                        )
                    })}
                </Masonry>
            }
        </>
    )
}