import { useTranslation } from "react-i18next";
import DefaultContainer from "../shared/DefaultContainer";
import Title from "../shared/Title";
import DayTurnList from "./DayTurnList";
import SpecialistSelect from '../specialist/SpecialistSelect';
import { Container, Divider, Stack } from "@mui/material";

const DayTurnListPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <DefaultContainer plain sx={{mb:2}}>
                <Title>{t('Turns')}</Title>
            </DefaultContainer>
            <Container>
                <Stack spacing={2}>
                    <SpecialistSelect access={{
                        entity: "Turn",
                        code: "HANDLE",
                        constraint: "Specialist"
                    }}/>
                    <DayTurnList />
                </Stack>
            </Container>
        </>
    );
}

export default DayTurnListPage;