import { 
    FormAutocomplete
} from '../shared/form-components';
import { graphql } from '@apollo/client/react/hoc';
import {
    GET_SPECIALISTS,
} from '../graphql/queries'
import { getSpecialistText } from '../shared/label-helpers';

export const FormAutocompleteGetSpecialistsQuery = graphql(GET_SPECIALISTS, {
    props: ({ data: { getSpecialists , loading}, ownProps: {_id} }, lastProps) => ({
        options: getSpecialists || [],
        loading: loading,
        getOptionLabel: getSpecialistText,
    }),   
    options: ({tenantId}) => ({
        variables: {
            tenants: [tenantId]
        }
    })
})(FormAutocomplete);
