import moment from "moment";
import { formatMoney } from "./format-helpers";

export const getSpecialistText = (specialist) => {
    if (!specialist) {
        return specialist;
    }

    if ( typeof specialist === "string") {
        return specialist;
    }  
    return `${specialist.name} - ${specialist.specialty?.name}`;
}

export const getSpecialtyText = (specialty) => {
    if (!specialty) {
        return specialty;
    }

    if ( typeof specialty === "string") {
        return specialty;
    }  
    return `${specialty.name}`;
}

export const getServiceText = (service) => {
    if (!service) {
        return service;
    }

    if ( typeof service === "string") {
        return service;
    }

    if (service.specialist){
        return `${service.name} (${getSpecialistText(service.specialist)}) ${formatMoney(service.amount)}`.trim();
    } else {
        return service.name;    
    }
}

export const getServiceMediumText = (service) => {
    if (!service) {
        return service;
    }

    if ( typeof service === "string") {
        return service;
    }

    if (service.specialist){
        return `${service.name} - ${getSpecialistText(service.specialist)}`.trim();
    } else {
        return service.code;    
    }
}

export const getPatientText = (patient) => {
    if (!patient) {
        return patient;
    }

    if ( typeof patient === "string") {
        return patient;
    }

    return `${patient.patientName} ${ patient?.patientBirthday ? `${moment(patient.patientBirthday).format('l')} (${moment(patient.patientBirthday).fromNow()})` : ''}${ (patient.tutorFirstName || patient.tutorFirstName) ? `, Tutor: ${patient.tutorFirstName||''} ${patient.tutorLastName||''}`.trim(): '' }, id: ${patient._id}`
}

export const getPatientSmallText = (patient) => {
    if (!patient) {
        return patient;
    }

    if ( typeof patient === "string") {
        return patient;
    }

    return `${patient.patientName} ${ patient?.patientBirthday ? `${moment(patient.patientBirthday).format('l')}` : ''}${ (patient.tutorFirstName || patient.tutorFirstName) ? `, Tutor: ${patient.tutorFirstName||''} ${patient.tutorLastName||''}`.trim(): '' }`
}

export const getAppointmentText = (appointment) => {
    if (!appointment) {
        return "";
    }

    if ( typeof appointment === "string") {
        return appointment;
    }
    return `${moment(appointment.date).format('l')}:${appointment.time} ${appointment.service.name} - ${appointment._id}`;
}

export const getAppointmentMediumText = (appointment) => {
    if (!appointment) {
        return "";
    }

    if ( typeof appointment === "string") {
        return appointment;
    }
    return `${appointment.code} - ${getServiceText(appointment.service)}`;

}

export const getOfficeText = (office) => {
    if (!office) {
        return office;
    }

    if ( typeof office === "string") {
        return office;
    }  
    return `${office.name}`;
}

export const getInsuranceText = (insurance) => {
    if (!insurance) {
        return insurance;
    }

    if ( typeof insurance === "string") {
        return insurance;
    }  
    return `${insurance.name}`;
}