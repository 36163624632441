import { Box, Button, Divider, Stack, Paper } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useForm } from "react-hook-form";
import LoadingButton from '@mui/lab/LoadingButton';

import { useTranslation } from 'react-i18next'
import Title from '../shared/Title';
import { FormTextField, FormDatePicker } from '../shared/form-components';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@apollo/client';
import { FIND_PATIENT_BY_ID, GET_PAGINATED_PATIENTS } from '../graphql/queries';
import { CREATE_PATIENT, UPDATE_PATIENT } from '../graphql/mutations';
import * as moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTenant, useTenantId } from '../tenant/hooks';

export default ({patientId, onCancel, onAdded}) => {
    const isNew = !patientId;
    const [tenant] = useTenant();
    const [tenantId] = useTenantId();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const buildPatientSchema = (tenant)=> {
        return yup.object({
            userId: yup.string(),
            patientName: yup.string().required(t('RequiredField', {Field: t('Name')})),
            patientBirthday: (
                tenant?.settings?.patientSettings?.birthdayRequired?
                    yup.date().typeError(t('InvalidDate')).required() :
                    yup.date().typeError(t('InvalidDate'))
            ),
            tutorFirstName: yup.string(),
            tutorLastName: yup.string(),
            tutorPhoneNumber: (
                tenant?.settings?.patientSettings?.phoneNumberRequired?
                    yup.string().required() :
                    yup.string()
            ),
            tutorIdentification: yup.string()
        });
    }

    const schema = useMemo(()=>{
        return buildPatientSchema(tenant)
    }, [tenant]);

    const [ createPatient, { data, loading, error }] = useMutation(isNew ? CREATE_PATIENT : UPDATE_PATIENT,{
        refetchQueries: [
            GET_PAGINATED_PATIENTS,
            FIND_PATIENT_BY_ID
        ]
    })
 
    const {data: patientData, loading: patientLoading} = useQuery(FIND_PATIENT_BY_ID, {
        variables: {
            patientId: patientId
        },
        skip: isNew
    });
    
    const { setValue, control, handleSubmit, formState: { isSubmitting } } = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        defaultValues: {
            _id: patientId
        }
    });

    const onSubmit = async data => {
        const response = await createPatient({
            variables: {
                input: data,
                ...(isNew ? {tenants:[tenantId]}: {})
            }
        });
        onAdded && onAdded({
            ...data,
            ...response.data.createPatient
        });

        enqueueSnackbar(t("OperationDoneSuccessfully"), {variant: "success"});
    }

    useEffect(()=>{
        if (!patientData?.findPatient) {
            return;
        }

        setValue("patientName", patientData.findPatient.patientName);
        setValue("patientBirthday", patientData.findPatient.patientBirthday ? moment(patientData.findPatient.patientBirthday) : undefined);
        setValue("tutorFirstName", patientData.findPatient.tutorFirstName ?? '');
        setValue("tutorLastName", patientData.findPatient.tutorLastName ?? '');
        setValue("tutorPhoneNumber", patientData.findPatient.tutorPhoneNumber ?? '');
        setValue("tutorIdentification", patientData.findPatient.tutorIdentification ?? '');
      
    }, [patientData]);

    return (
        <Paper data-testid="patient-form">
            <Box p={2}>
                <Title sx={{marginBottom: 0}}>{t(isNew?"CreatePatient":"EditPatient")}</Title>
            </Box>
            <Divider />
            <Box p={2}>
                <Stack spacing={2}>
                    <FormTextField 
                        label={t('PatientName')}
                        name="patientName"
                        variant="outlined"
                        control={control}
                        fullWidth
                    />
                    <FormDatePicker 
                        label={t("PatientBirthday")}
                        control={control}
                        name="patientBirthday"
                        />
                    <Divider />
                    <FormTextField
                        label={t('TutorFirstName')}
                        variant="outlined"
                        fullWidth
                        control={control}
                        name="tutorFirstName"
                    />
                    <FormTextField
                        label={t('TutorLastName')}
                        variant="outlined"
                        fullWidth
                        control={control}
                        name="tutorLastName"
                    />
                    <FormTextField
                        label={t('TutorPhoneNumber')}
                        variant="outlined"
                        type="tel"
                        fullWidth
                        control={control}
                        name="tutorPhoneNumber"
                    />
                    <FormTextField
                        label={t('TutorIdentification')}
                        variant="outlined"
                        fullWidth
                        control={control}
                        name="tutorIdentification"
                    />
                </Stack>
            </Box>
            <Divider />
            <Box p={2}>
                <Stack direction="row" justifyContent="space-between" spacing={2}>
                    <Button data-testid="patient-form-cancel-btn" variant='outlined' color="error" onClick={onCancel} disabled={isSubmitting}>{t('Cancel')}</Button>
                    <LoadingButton data-testid="patient-form-submit-btn" fullWidth variant='contained' color='primary' onClick={handleSubmit(onSubmit)} loading={isSubmitting}>{t('Submit')}</LoadingButton>
                </Stack>
            </Box>
        </Paper>
    )
}