import { Box, Paper, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DefaultContainer from "../shared/DefaultContainer";
import Title from "../shared/Title";
import SpecialistSelect from "../specialist/SpecialistSelect";
import AppointmentCalendar from "./AppointmentCalendar";
import { useSpecialists } from "../specialist/hooks";

export default () => {
    const { t } = useTranslation();
    const [specialists] = useSpecialists();

    return (
        <DefaultContainer plain>
            <Stack spacing={2}>
                <Title>{t('Appointments')}</Title>
                <SpecialistSelect access={{
                    entity: "Appointment",
                    code: "CALENDAR"
                }}/>
                {
                    specialists && specialists.length>0 ?
                    (<AppointmentCalendar specialists={specialists} />)
                    : 
                    (<Typography>
                        {t('PleaseSelectSpecialists')}
                    </Typography>)
                }
            </Stack>
        </DefaultContainer>
    );
}